<template>
  <div id="main-div">
    <h5 class="pl-1 font-weight-bold mb-1">CREW PROFILE </h5>
    <div class="row pl-1">
      <div class="col-2">
        <img :src="crewImage" v-if="crewImage" alt="Crew Image" class="img-thumbnail crew-pic">
        <img v-else src="/no_photo.png" alt="No Cre Image" class="img-thumbnail crew-pic">
      </div>
      <div class="col-10">
        <div>
          <div class="row align-content-center">
            <div class="col-sm-6">
              <h5 class="font-weight-bolder">{{crewInfo.fullName ? crewInfo.fullName :''}} ({{crewInfo.rank ? crewInfo.rank.toUpperCase() : ''}}, {{crewInfo.ipn ? crewInfo.ipn.toUpperCase() : ''}})</h5>
            </div>
            <div class="col-sm-6 text-right">
              <button class="btn btn-outline-secondary btn-xs mr-2" data-target="#crewBiodata" data-toggle="modal" :disabled="!biodata">Manning Biodata</button>
              <button class="btn btn-outline-secondary btn-xs mr-2" data-target="#crewConsent" data-toggle="modal" :disabled="!consent">Consent</button>
            </div>
          </div>
          <div class="row mt-3 text-sm">
            <div class="col col-sm-4">
              <table class="w-100">
                <tr>
                  <td>Crew IPN:</td>
                  <td class="font-weight-bold">{{crewInfo.ipn ? crewInfo.ipn.toUpperCase() : ''}}</td>
                </tr>
                <tr>
                  <td>Rank:</td>
                  <td class="font-weight-bold">{{crewInfo.rank ? crewInfo.rank.toUpperCase() : ''}}</td>
                </tr>
                <tr>
                  <td>Manning:</td>
                  <td class="font-weight-bold">{{crewInfo.manning ? crewInfo.manning.toUpperCase() : ''}}</td>
                </tr>
                <tr>
                  <td>Nationality:</td>
                  <td class="font-weight-bold">{{crewInfo.nationality ? crewInfo.nationality.toUpperCase() : ''}}</td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td class="font-weight-bold">{{crewInfo.status}}</td>
                </tr>
                <tr>
                  <td>Current Activity:</td>
                  <td class="font-weight-bold">{{crewInfo.activityCode ? crewInfo.activityCode.toUpperCase() : ''}}</td>
                </tr>
                <tr>
                  <td>Crew Type:</td>
                  <td class="font-weight-bold">{{crewInfo.crewType ? crewInfo.crewType.toUpperCase() : ''}}</td>
                </tr>
                <tr>
                  <td>School:</td>
                  <td class="font-weight-bold" v-if="crewInfo.education">
                    {{crewInfo.education.school}} - {{crewInfo.education.course}} ({{crewInfo.education.date_graduated}})
                  </td>
                  <td v-else></td>
                </tr>
              </table>
            </div>
            <div class="col col-sm-4">
              <table class="w-100">
                <tr>
                  <td>Current Vessel:</td>
                  <td class="font-weight-bold" v-if="crewInfo.currentActivity">{{crewInfo.currentActivity.vesselName}}</td>
                </tr>
                <tr>
                  <td>Vessel Type:</td>
                  <td class="font-weight-bold"  v-if="crewInfo.currentActivity">
                    {{crewInfo.currentActivity.vesselType ? crewInfo.currentActivity.vesselType.toUpperCase() : ''}}
                  </td>
                </tr>
                <tr>
                  <td>End Of Contract:</td>
                  <td class="font-weight-bold" v-if="crewInfo.currentActivity">
                    {{crewInfo.currentActivity.endDate}}
                  </td>
                </tr>
                <tr>
                  <td>Embarked:</td>
                  <td class="font-weight-bold" v-if="crewInfo.currentActivity">
                    {{crewInfo.currentActivity.startDate}}
                  </td>
                </tr>
<!--                <tr>-->
<!--                  <td>Created By:</td>-->
<!--                  <td class="font-weight-bold"  v-if="crewInfo.createdBy">{{crewInfo.createdBy}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>Created At:</td>-->
<!--                  <td class="font-weight-bold" v-if="crewInfo.createdAt">{{crewInfo.created_at}}</td>-->
<!--                </tr>-->
              </table>
            </div>
            <div class="col col-sm-4">
              <table class="w-100">
                <tr>
                  <td>Last Vessel:</td>
                  <td class="font-weight-bold" v-if="crewInfo.lastVesselActivity">
                    {{crewInfo.lastVesselActivity.vesselName}}
                  </td>
                </tr>
                <tr>
                  <td>Last Sign-off Date:</td>
                  <td class="font-weight-bold" v-if="crewInfo.lastVesselActivity">
                    {{crewInfo.lastVesselActivity.end_date}}
                  </td>
                </tr>
                <tr>
                  <td>Next Planned Vessel:</td>
                  <td class="font-weight-bold" v-if="crewInfo.nextVesselActivity">
                    {{crewInfo.nextVesselActivity.vesselName}}
                  </td>
                </tr>
                <tr>
                  <td>Next Planned Sign-on Date:</td>
                  <td class="font-weight-bold" v-if="crewInfo.nextVesselActivity">
                    {{crewInfo.nextVesselActivity.startDate}}
                  </td>
                </tr>
<!--                <tr>-->
<!--                  <td>Last Updated By:</td>-->
<!--                  <td class="font-weight-bold" v-if="crewInfo.updatedBy">-->
<!--                    {{crewInfo.updatedBy}}-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>Last Updated At:</td>-->
<!--                  <td class="font-weight-bold" v-if="crewInfo.updatedAt">-->
<!--                    {{crewInfo.updatedAt}}-->
<!--                  </td>-->
<!--                </tr>-->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-1">
      <nav class="navbar navbar-expand-lg mt-2 navbar-light" id="ModelNav">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse " id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'CrewProfile'}">
                <span>201 Profile</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'CrewDocument'}">
                <span>Documents</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'CrewActivity'}">
                <span>Crew Activity</span>
              </router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="{name:'CrewCer'}">
                <span>CER</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'Experience'}">
                <span>Work Experience</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'CrewBehavior'}">
                <span>Crew Incident / Behavior</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'Issues'}">
                <span>Medical Issues</span>
              </router-link>
            </li>
<!--            <li class="nav-item">-->
<!--              <a class="nav-link">-->
<!--                <span>Crew Feedback</span>-->
<!--              </a>-->
<!--            </li>-->
          </ul>
        </div>
      </nav>
      <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px" />
    </div>
    <div class="modal fade" id="crewBiodata" tabindex="-1" aria-labelledby="crewBiodata" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h5 class="modal-title" id="crewBiodataTitle">{{crewInfo.fullName}}'s Biodata</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <iframe style="width: 100%; height: 80vh;" :src="biodata"></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="crewConsent" tabindex="-1" aria-labelledby="crewConsent" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h5 class="modal-title" id="crewConsentTitle">{{crewInfo.fullName}}'s Consent</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <iframe style="width: 100%; height: 80vh;" :src="consent"></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "CrewInfo",
  data(){
    return{
      crewImage:false,
      biodata:false,
      consent:false,
    }
  },
  async created() {
    const params={
      cms_id:this.$route.params.id,
    }
    await this.getCrewInfo(params);
    await this.getCrewImage(params)
    await this.getCrewBiodata(params);
    await this.getCrewConsent(params);
  },
  computed:{
    ...mapGetters(['crewInfo'])
  },
  methods:{
    ...mapActions(['getCrewInfo']),

    async getCrewImage(params){
      const image = await CrewService.getCrewImageFromCms(params)
      this.crewImage=image ? image : false;
    },
    async getCrewBiodata(params){
      const biodata=await CrewService.getCrewBiodataFromCms(params)
      this.biodata=biodata.file ?FileService.base64FileToBlobUrl(biodata?.file) : false;
    },

    async getCrewConsent(params) {
      const consent=await CrewService.getCrewConsentFromCms(params)
        this.consent=consent.file ? FileService.base64FileToBlobUrl(consent?.file) : false;
    }
  }
}
</script>

<style scoped lang="scss">
.main-div{
  overflow-x: hidden;
}
.crew-pic{
  width: 250px;
  height: auto;
}
.custom-display{
  border: none !important;
  background-color: transparent !important;
  font-weight: normal !important;
  border-radius: 0 !important;
}
.display-height {
  height: 20px !important;
}
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;
  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}
.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;
  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}
#ModelNav {
  padding: 0;
  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }
  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}
.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  * {
    color: #d3313c;
  }
}
table td {
  padding: 2px;
}
</style>
